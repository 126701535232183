import TitleBlock from "../Components/TitleBlock/TitleBlock";
import {useEffect, useState} from "react";
import {collection, getDocs, query, where} from "firebase/firestore";
import {db, storage} from "../firebase";
import {getDownloadURL, ref, listAll} from "firebase/storage";
import PlanningGridItem from "../Components/PlanningGrid/PlanningGridItem";

function Planning(){
    const [urlImages, setUrlImages] = useState([
        { url: "/assets/planning/cartes.png", id: "cartes" },
        { url: "/assets/planning/animation.png", id: "animation" },
        { url: "/assets/planning/exposant.png", id: "exposant" },
        { url: "/assets/planning/figurines.png", id: "figurines" },
        { url: "/assets/planning/gn.png", id: "gn" },
        { url: "/assets/planning/jdr.png", id: "jdr" }
    ]);

    const [activityExpo, setActivityExpo] = useState([]);
    const [activitySat, setActivitySat] = useState([]);
    const [activitySun, setActivitySun] = useState([]);
    const [activityMon, setActivityMon] = useState([]);
    const [descriptionPlanning, setDescriptionPlanning] = useState([]);

    const fetchPresentationPlanning = async () => {
        const q = query(collection(db, "presentation"), where("type", "in", ["planning_current_edition", "planning_last_edition"]));
        const querySnapshotPresentation = await getDocs(q);
        setDescriptionPlanning(querySnapshotPresentation.docs.map((doc) => doc.data()));
    }

    const fetchActivities = async () => {
        const qAct = query(collection(db, "planning"));
        const querySnapshotActivities = await getDocs(qAct);
        querySnapshotActivities.docs.map((doc) => {
            const obj = {
                title: doc.data().title,
                start: doc.data().start.toDate(),
                end: doc.data().end.toDate(),
                capacity: doc.data().participants,
                type: doc.data().type,
                description: doc.data().description
            };

            if(obj.type === "exposant")
            {
                setActivityExpo(activities => [...activities, obj]);
            }
            else if(obj.start.getDay() === 6)
            {
                setActivitySat(activities => [...activities , obj]);
            }
            else if(obj.start.getDay() === 0)
            {
                setActivitySun(activities => [...activities , obj]);
            }
            else if(obj.start.getDay() === 1)
            {
                setActivityMon(activities => [...activities , obj]);
            }
            return null;
        });
    }

    const fetchImages = () => {
        const postsRef = ref(storage, 'planning');
        listAll(postsRef).then(result => {
            // Loop over each item
            result.items.forEach((pics, index) => {
                getDownloadURL(ref(storage, pics.fullPath))
                    .then((url) => {
                        // This can be downloaded directly:
                        const xhr = new XMLHttpRequest();
                        xhr.responseType = 'blob';
                        xhr.onload = (event) => {
                            const blob = xhr.response;
                        };
                        xhr.open('GET', url);
                        xhr.send();

                        const name  = url.split('?').at(0).split('%2F').at(-1).split('.').at(0);
                        const objectImage = {
                            url: url,
                            id: name
                        };
                        setUrlImages(objects => [...objects, objectImage]);
                        //console.log(name + " : " + url);
                    })
                    .catch((error) => {
                        // Handle any errors
                    })
            });
        })
    }

    useEffect( () => {
        //Presentation Text
        fetchPresentationPlanning().then(() => {
            //console.log('Text request Firebase');
        });

        //Planning data
        fetchActivities().then(() => {
            console.log('Activities request Firebase');
        });
        //fetchImages();

        return () => {};
    }, []);

    const getUrlFromName = (name) =>
    {
        for (let i=0; i < urlImages.length; i++) {
            const image = urlImages.at(i);
            if(image.id === name)
            {
                return image.url;
            }
        }
    }

    const showMoreActivities = (e, day) => {
        e.preventDefault();
        const activitySection = document.querySelector("div#activitySection" + day);
        const button = document.querySelector("button#showMore"+ day +"Button span")
        if (activitySection.classList.contains("showMoreRow")){
            activitySection.classList.remove("showMoreRow");
            activitySection.classList.add("showLessRow");
            button.innerText = "Afficher moins"
        } else {
            activitySection.classList.remove("showLessRow");
            activitySection.classList.add("showMoreRow");
            button.innerText = "Afficher davantage"
        }
    }

    return(
        <div className="page" id={'page_planning'}>
            <meta
                name={"description"}
                content={"Un résumé unique de toutes les activités et convention des croisades. Venez découvrir nos " +
                    "passions !"}
            />
            <TitleBlock title="Planning"/>
            <div className="align-center-web row" key="planning_current_edition">
                {descriptionPlanning.map((text) =>
                    {
                        if (text.type === "planning_current_edition") {
                            return <p>{text.description}</p>
                        }
                    }
                )}
            </div>
            <div className="align-center-web row">
                <div className="col-lg-12 overflow-hidden">
                    <h2>Exposants</h2>
                    <div className="row">
                        <div className="row showMoreRow" id="activitySectionExpo">
                            {
                                activityExpo.sort((a, b) => (a.end.getTime() - a.start.getTime()) - (b.end.getTime() - b.start.getTime())).map((activity, index) => {
                                    const url = getUrlFromName(activity.type);
                                    return <PlanningGridItem
                                        id_card={"expo_card_" + index}
                                        title={activity.title}
                                        start={activity.start}
                                        end={activity.end}
                                        capacity={activity.capacity}
                                        category={activity.type}
                                        description={activity.description}
                                        imageUrl={url}
                                    ></PlanningGridItem>
                                })
                            }
                        </div>
                        <button onClick={(e) => showMoreActivities(e, "Expo")} id="showMoreExpoButton" className="displayInMobileVersion" role="button"><span className="text">Afficher davantage</span></button>
                    </div>
                    <h2>Samedi</h2>
                    <div className="row">
                        <div className="row showMoreRow" id="activitySectionSat">
                            {
                                activitySat.sort((a, b) => a.start.getTime()  - b.start.getTime()).map((activity, index) => {
                                    const url = getUrlFromName(activity.type);
                                    return <PlanningGridItem
                                        id_card={"activity_sat_card_" + index}
                                        title={activity.title}
                                        start={activity.start}
                                        end={activity.end}
                                        capacity={activity.capacity}
                                        category={activity.type}
                                        description={activity.description}
                                        imageUrl={url}
                                    ></PlanningGridItem>
                                })
                            }
                        </div>
                        <button onClick={(e) => showMoreActivities(e,"Sat")} id="showMoreSatButton" className="displayInMobileVersion" role="button"><span className="text">Afficher davantage</span></button>
                    </div>
                    <h2>Dimanche</h2>
                    <div className="row">
                        <div className="row showMoreRow" id="activitySectionSun">
                            {
                                activitySun.sort((a, b) => a.start.getTime()  - b.start.getTime()).map((activity, index) => {
                                    const url = getUrlFromName(activity.type);
                                    return <PlanningGridItem
                                        id_card={"activity_sun_card_" + index}
                                        title={activity.title}
                                        start={activity.start}
                                        end={activity.end}
                                        capacity={activity.capacity}
                                        category={activity.type}
                                        description={activity.description}
                                        imageUrl={url}
                                    ></PlanningGridItem>
                                })
                            }
                        </div>
                        <button onClick={(e) => showMoreActivities(e,"Sun")} id="showMoreSunButton" className="displayInMobileVersion"><span className="text">Afficher davantage</span></button>
                    </div>
                    <h2>Lundi</h2>
                    <div className="row">
                        <div className="row showMoreRow" id="activitySectionMon">
                            {
                                activityMon.sort((a, b) => a.start.getTime()  - b.start.getTime()).map((activity, index) => {
                                    const url = getUrlFromName(activity.type);
                                    return <PlanningGridItem
                                        id_card={"activity_mon_card_" + index}
                                        title={activity.title}
                                        start={activity.start}
                                        end={activity.end}
                                        capacity={activity.capacity}
                                        category={activity.type}
                                        description={activity.description}
                                        imageUrl={url}
                                    ></PlanningGridItem>
                                })
                            }
                        </div>
                        <button onClick={(e) => showMoreActivities(e,"Mon")} id="showMoreMonButton" className="displayInMobileVersion"><span className="text">Afficher davantage</span></button>
                    </div>

                </div>
            </div>
            {/*<div className="align-center-web row" key="planning_last_edition">
                <h2>Edition 2022</h2>
                {descriptionPlanning.map((text) => {
                        if (text.type === "planning_last_edition") {
                            return <p>{text.description}</p>
                        }
                    }
                )}
            </div>*/}
        </div>
    );
}

export default Planning;