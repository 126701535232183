import './Footer.css'

function FooterComment(props){

    let {text} = props;

    return(
        <p className={'footerComment'}>{text}</p>
    );
}

export default FooterComment;