import "./PlanningGrid.css";

function PlanningGridItem(props){

    let {id_card, title, category, imageUrl, start, end, description, capacity} = props;

    const dropDownDescription = (e) => {
        e.preventDefault();
        let currentActivityDescription = document.querySelector('#' + id_card + ' div.planning-card-description.test-card.test-card--disapear');
        let allActivitiesDescription = document.querySelectorAll('div.planning-card-description.test-card.test-card--disapear');

        if (currentActivityDescription.classList.contains('displayed')){
            currentActivityDescription.classList.remove('displayed');
            currentActivityDescription.classList.add('out');
        } else {
            allActivitiesDescription.forEach( activity => {
                if (activity.classList.contains('displayed')){
                    activity.classList.remove('displayed');
                } else if (activity.id === "planning-card-description-" + id_card){
                    currentActivityDescription.classList.remove('out');
                    currentActivityDescription.classList.add('displayed');
                }
            });
        }
    }

    const fromDayToFrench = (day) => {
        if (day === 6){
            return "Samedi"
        } else if (day === 0){
            return "Dimanche"
        } else if (day === 1){
            return "Lundi"
        }
    }

    const hour_overview = () => {
            if (start.getDay() === end.getDay()){
                return fromDayToFrench(start.getDay())
            } else {
                return fromDayToFrench(start.getDay()) + " au " + fromDayToFrench(end.getDay())
            }
    }

    if(category === "exposant"){
        return(
            <div className="col-md-3">
                <div className="planning-card" id={id_card}>
                    <div onClick={dropDownDescription} className="planning-card-overview test-card test-card--trigger bg-image" style={{backgroundImage: 'linear-gradient(rgba(80, 79, 79, 0.4), rgba(80, 79, 79, 0.7)),url('+ imageUrl +')'}}>
                        <h5>{title}</h5>
                        <p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                fill="#FFFFFF"
                                className="bi bi-calendar-fill"
                                viewBox="0 0 16 16">
                                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5h16V4H0V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5z"/>
                            </svg>
                            {hour_overview()}
                        </p>
                    </div>
                    <div className={"planning-card-description test-card test-card--disapear"} id={"planning-card-description-" + id_card}>
                        <p id="detail_planning_description">{description}</p>
                    </div>
                </div>
            </div>
        );
    } else {
        return(
            <div className="col-md-3">
                <div className="planning-card" id={id_card}>
                    <div onClick={dropDownDescription} className="planning-card-overview test-card test-card--trigger bg-image" style={{backgroundImage: 'linear-gradient(rgba(80, 79, 79, 0.4), rgba(80, 79, 79, 0.7)),url('+ imageUrl +')'}}>
                        <h5>{title}</h5>
                        <p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                fill="#FFFFFF"
                                className="bi bi-clock-fill"
                                viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                            </svg>
                            {start.getHours().toString()}h
                        </p>
                        <p className="card-text">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                fill="#FFFFFF"
                                className="bi bi-person.fill"
                                viewBox="0 0 16 16">
                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"/>
                            </svg>
                            {capacity}
                        </p>
                    </div>
                    <div className={"planning-card-description test-card test-card--disapear"} id={"planning-card-description-" + id_card}>
                        <p id="detail_planning_hours" >{ "De " + start.getHours().toString() + "h à " + end.getHours().toString() + "h" } <span className="badge">{category.toUpperCase()}</span></p>
                        <p id="detail_planning_description">{description}</p>
                    </div>
                </div>
            </div>
        );
    }


}

export default PlanningGridItem;