import './TitleBlock.css'

function TitleBlock(props){

    let {title, children} = props;

    return(
        <div className='position-relative text-center titleBlock'>
            <h1 id="titleBlock" className='display-3 font-weight-normal titleBlockTitle'>{title}</h1>
            {children}
        </div>
    );
}

export default TitleBlock;