import './InstagramPost.css';
import {HashLink} from "react-router-hash-link";

function InstagramPost(props){
    let {title, date, description, imageName, imageUrl, link} = props;

    return(
        <HashLink
            smooth
            className={'col-lg-6 instagramPost'}
            to={link}
        >
                <div className="row g-0 border rounded overflow-hidden shadow-sm flex-md-row mb-4 h-md-250 position-relative">
                    <div className="col-8 p-4 d-flex flex-column position-static">
                        <h3 className="mb-0">{title}</h3>
                        <div className="mb-1 text-muted">{date}</div>
                        <p className="mb-auto">{description}</p>
                    </div>
                    <div className="col-sm-4 instagramPostImageWrapper">
                        <img
                            className="bd-placeholder-img instagramPostImage"
                            role="img"
                            aria-label={title}
                            focusable="false"
                            src={imageUrl}
                            id={imageName}
                            alt={title}
                        />
                    </div>
                </div>
        </HashLink>
    );
}

export default InstagramPost;