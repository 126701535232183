function LocationItem(props){

    let {title, content} = props;
    console.log(content.length);
    return(
        <div className="locationItem">
            <h3>{title}</h3>
            {
                content.map((transport) => {
                    return(
                        <p>
                            <span className={"badge transports " + transport.name}>{transport.name}</span>
                            {transport.description}
                        </p>
                    );
                })
            }
        </div>
    );
}

export default LocationItem;