function SponsorsRow(props){

    let {id, children} = props;

    return(
        <div id={'sponsors_row_'+id} className='row align-center-web'>
            {children}
        </div>
    );
}

export default SponsorsRow;