import './Location.css'

function Location(props){

    let {title, content, children} = props;

    return(
        <div id={'location_row'} className={'locationBlock'}>
            <div className={'row'}>
                <div className={'col-lg-12'}>
                    <h2>{title}</h2>
                    <p>{content}</p>
                </div>
            </div>
            <div className={"row locationSection"}>
                <div className={'col-md-6 rounded-card-white overflow-hidden'}>
                    <iframe
                        title={"Emplacement de la convention"}
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2530.6451659896607!2d3.0430197158948635!3d50.633708182023895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c2d5795af8fd31%3A0x6932e8ba296a5afc!2s13%20Rue%20de%20Toul%2C%2059000%20Lille!5e0!3m2!1sen!2sfr!4v1646769524841!5m2!1sen!2sfr"
                        style={{border: 0}} allowFullScreen="" loading="lazy" />
                </div>
                <div className={'col-md-6'}>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Location;