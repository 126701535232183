import './PresentationBlock.css';

function PageBlock(props){

    let {children} = props;

    return(
        <div className={'pageBlock'}>
            {children}
        </div>
    );
}

export default PageBlock;