import FooterLinkItem from "./FooterLinkItem";

function FooterLinksList(){
    return(
        <ul className='list-unstyled nav-links mb-5 row footerLinksList'>
            <FooterLinkItem link='/#titleBlock' text='Accueil'/>
            <FooterLinkItem link='/planning/#titleBlock' text='Planning'/>
            <FooterLinkItem link='/actualites/#titleBlock' text='Actualités'/>
            <FooterLinkItem link='/informations_pratiques/#titleBlock' text='Informations pratiques'/>
        </ul>
    );
}

export default FooterLinksList;