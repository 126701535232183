import '../WhereToSleepBlock/WhereToSleepBlock.css'

function WhereToSleepBlock(props){
    let {children, id, title, content} = props;

    return(
        <div id={id} className={'whereToSleepBlock'}>
            <h2 id={id+'_title'}>{title}</h2>
            <p>{content}</p>
            <div className={'row rounded-card-white'}>
                {children}
            </div>
        </div>
    );
}

export default WhereToSleepBlock;