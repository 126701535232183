import TitleBlock from "../Components/TitleBlock/TitleBlock";
import ContactBlock from "../Components/ContactBlock/ContactBlock";
import Location from "../Components/Location/Location";
import '../Components/Location/Location.css';
import {ExternalLink} from "react-external-link";
import WhereToSleepItem from "../Components/WhereToSleepBlock/WhereToSleepItem";
import WhereToSleepBlock from "../Components/WhereToSleepBlock/WhereToSleepBlock";
import LocationItem from "../Components/Location/LocationItem";
import {useEffect, useState} from "react";
import {collection, getDocs, query} from "firebase/firestore";
import {db, storage} from "../firebase";
import {getDownloadURL, listAll, ref} from "firebase/storage";

function InformationsPratiques(){
    const [transports, setTransports] = useState([]);
    const [titles, setTitles] = useState([]);
    const [hotels, setHotels] = useState([]);
    const [urlHotelsImages, setUrlHotelsImages] = useState([
        { url: "/assets/hotels/Dortoir_Croisades.png", id: "Dortoir_Croisades" },
        { url: "/assets/hotels/adagiohotel.jpg", id: "adagiohotel" },
        { url: "/assets/hotels/calmAppartHotel.jpg", id: "calmAppartHotel" }
    ]);

    const fetchTransportation = async () => {
        const q = query(collection(db, "transports"));
        const querySnapshotSponsors = await getDocs(q);
        setTransports(querySnapshotSponsors.docs.map((doc) => doc.data()));
    }

    const fetchPresentation = async () => {
        const q = query(collection(db, "presentation"));
        const querySnapshotSponsors = await getDocs(q);
        setTitles(querySnapshotSponsors.docs.map((doc) => doc.data()));
    }

    const fetchHotels = async () => {
        const q = query(collection(db, "hotels"));
        const querySnapshotSponsors = await getDocs(q);
        setHotels(querySnapshotSponsors.docs.map((doc) => doc.data()));
    }

    const fetchHotelsImages = () => {
        const postsRef = ref(storage, 'hotels');
        listAll(postsRef).then(result => {
            // Loop over each item
            result.items.forEach((pics, index) => {
                getDownloadURL(ref(storage, pics.fullPath))
                    .then((url) => {
                        // This can be downloaded directly:
                        const xhr = new XMLHttpRequest();
                        xhr.responseType = 'blob';
                        xhr.onload = (event) => {
                            const blob = xhr.response;
                        };
                        xhr.open('GET', url);
                        xhr.send();

                        // Or inserted into an <img> element
                        const name  = url.split('?').at(0).split('%2F').at(-1).split('.').at(0);
                        const objectImage = {
                            id: name,
                            url: url
                        };
                        setUrlHotelsImages(objects => [...objects, objectImage]);
                    })
                    .catch((error) => {
                        // Handle any errors
                    })
            });
        });
    }

    useEffect( () => {
        //Public transportation
        fetchTransportation().then(() => {
            //console.log('Transportation request Firebase');
        });

        //Page presentation text
        fetchPresentation().then(() => {
            //console.log('Presentation request Firebase');
        });

        //Hotels description
        fetchHotels().then(() => {
            //console.log('Hotels request Firebase');
        });

        //Hotels Images
        //fetchHotelsImages();

        return () => {};
    }, []);

    const getUrlFromName = (name) =>
    {
        for (let i=0; i < urlHotelsImages.length; i++) {
            const image = urlHotelsImages.at(i);
            if(image.id === name)
            {
                return image.url;
            }
        }
    }

    return(
        <div className={'page align-center-web'} id={'page_informations_pratiques'}>
            <meta
                name={"description"}
                content={"Contact et informations pratiques vous faciliterons la vie lors de votre prochaine convention." +
                    " Trouvez toutes les informations dont vous pouvez avoir besoin et n'hésitez pas a contacter notre" +
                    " équipe."}
            />
            <TitleBlock title="La Convention"/>

            {
                titles.map((titleElement) => {
                    if(titleElement.type === "transports"){
                        let listMetro = [];
                        let listBus =  [];

                        transports.map((transport) => {
                            if(transport.type === "metro")
                            {
                                listMetro.push(transport);
                            }
                            else if (transport.type === "bus")
                            {
                                listBus.push(transport);
                            }
                        });

                        return(
                            <Location
                                title={"Accéder à la convention"}
                                content={titleElement.description}>

                                <LocationItem
                                    title={"Venir en métro"}
                                    content={listMetro}>
                                </LocationItem>
                                <LocationItem
                                    title={"Venir en Bus"}
                                    content={listBus}>
                                </LocationItem>
                                <ExternalLink
                                    id={"informations_pratiques_link_ilevia"}
                                    className={"btn locationLinkIlevia"}
                                    href={"https://www.ilevia.fr/"}
                                >
                                    Transports publiques Lillois : Ilévia >
                                </ExternalLink>
                                <div className="locationItem">
                                    <h3>Venir en voiture</h3>
                                    <p>En voiture, depuis l'A1 direction Port de Lille sortie ...</p>
                                </div>

                            </Location>
                        );
                    }
                    else if (titleElement.type === "logements")
                    {
                        return(
                            <WhereToSleepBlock
                                title={"Se loger"}
                                content={titleElement.description}
                                id={"Section_Se_Loger"}>
                                {
                                    hotels.map((hotel) => {
                                        return(
                                            <WhereToSleepItem
                                                title={hotel.name}
                                                img={hotel.img}
                                                link={hotel.link}
                                                content={hotel.description}
                                                url={getUrlFromName(hotel.img)}
                                            >
                                            </WhereToSleepItem>
                                        );
                                    })
                                }
                            </WhereToSleepBlock>
                        );
                    }
                    else if (titleElement.type === "restauration")
                    {
                        return(
                            <div className="row pb-2">
                                <div className="col-lg-12">
                                    <h2>Se restaurer</h2>
                                    <p>{titleElement.description}</p>
                                </div>
                            </div>
                        );
                    }
                })
            }
            <ContactBlock/>
        </div>
    );
}

export default InformationsPratiques;
