import NavBar from "./Navigation/NavBar/NavBar";
import Footer from "./Navigation/Footer/Footer";

function Layout(props){

    let {children} = props;

    return(
        <div id={'App'}>
            <NavBar/>
            <main>
                {children}
            </main>
            <Footer/>
            <script src=
                        "https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"
                    type="text/javascript" />
        </div>
    );
}

export default Layout;