import { Link } from 'react-router-dom'
import NavLinksList from "./NavLinksList";
import "./NavBar.css";

function NavBar() {
    return (
        <header className='bg-grey align-center-web'>
            <nav className='navbar navbar-expand-lg navbar-light bg-grey'>
                <Link to='/' className="navbar-brand">
                    <img src={"/assets/logos/BouclierRouge.PNG"} className="d-inline-block align-top shield"
                         alt="Bouclier des Croisades d'Unnord" />
                    <img src={"/assets/logos/logo_CU_Noir_Transparent.PNG"} className="d-inline-block align-top logo"
                         alt="Croisades d'Unnord" />
                </Link>
                <button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#navbarNav' aria-controls='navbarNav' aria-expanded='false' aria-label='Toggle navigation'>
                    <span className='navbar-toggler-icon' />
                </button>
                <div className='collapse navbar-collapse' id='navbarNav'>
                    <NavLinksList />
                </div>
            </nav>
            <hr className='hr hr-NavBar'/>
        </header>
    );
}

export default NavBar;