import {ExternalLink} from "react-external-link";
import './Sponsor.css';

function SponsorCard(props){

    let {title, link, img, name, text, url} =props;

    return(
        <div className="col-md-6 px-3">
            <ExternalLink id={title+'_card'} className="btn rounded-card-white w-100" href={link}>
                <div className='row'>
                    <div id={title+'_image'} className='col-sm-3'>
                        <img src={url} alt={name}/>
                    </div>
                    <div id={title+'_title'} className='col sponsorCardTitle'>
                        <h4><strong>{title}</strong></h4>
                        <div className="sponsorCardLine" />
                        <p className={"sponsorCardText"}>{text}</p>
                    </div>
                </div>
            </ExternalLink>
        </div>
    );
}

export default SponsorCard;