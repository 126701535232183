import TitleBlock from "../Components/TitleBlock/TitleBlock";
import PresentationBlock from "../Components/PresentationBlock/PresentationBlock";
import InstagramPostRow from "../Components/InstagramPost/InstagramPostRow";
import InstagramPost from "../Components/InstagramPost/InstagramPost";
import {useEffect, useState} from "react";
import {collection, getDocs, query, where} from "firebase/firestore";
import {db, storage} from "../firebase";
import {ref, getDownloadURL, listAll} from "firebase/storage";

function Accueil(props){
    const [imageUrl, setImageUrl] = useState('/assets/logos/2024-affiche.JPG');
    const [presentation, setPresentation] = useState([]);
    //const [articles, setArticles] = useState([]);
    //const [articlesUrl, setArticlesUrl] = useState([]);
    const articles = props.articles;
    const  articlesUrl = props.articlesUrl;

    const fetchPresentation = async () => {
        const q = query(collection(db, "presentation"), where("type", "==", "home"));
        const querySnapshotPresentation = await getDocs(q);
        setPresentation(querySnapshotPresentation.docs.map((doc) => doc.data()));
    }

    const fetchArticles = async () => {
        const querySnapshotArticles = await getDocs(collection(db, "articles"));
        //setArticles(querySnapshotArticles.docs.map((doc) => doc.data()));
    }

    const fetchImage = (storageName) => {
        const postsRef = ref(storage, storageName);
        listAll(postsRef).then(result => {
            // Loop over each item
            result.items.forEach((pics) => {
                getDownloadURL(ref(storage, pics.fullPath))
                    .then((url) => {
                        // This can be downloaded directly:
                        const xhr = new XMLHttpRequest();
                        xhr.responseType = 'blob';
                        xhr.onload = (event) => {
                            const blob = xhr.response;
                        };
                        xhr.open('GET', url);
                        xhr.send();

                        if(storageName === 'posts')
                        {
                            const name  = url.split('?').at(0).split('%2F').at(-1).split('.').at(0);
                            const object = {
                                url:url,
                                id:name
                            };
                            //setArticlesUrl(urls => [...urls,object]);
                        }
                        else if(storageName === 'affiche')
                        {
                            setImageUrl(url);
                        }
                    })
                    .catch((error) => {
                        // Handle any errors
                    })
            });
        })
    }

    useEffect(()=>{
        //Presentation Text
        fetchPresentation().then(() => {
            //console.log('Text request Firebase');
        });

        //Presentation Image
        //fetchImage('affiche');

        //Article List
        /*fetchArticles().then(() => {
            //console.log('Articles request Firebase');
        });
         */

        //Articles Images
        //fetchImage('posts');

        return () => {};
    }, []);

    const getUrlFromName = (name) =>
    {
        for (let i=0; i < articlesUrl.length; i++) {
            const image = articlesUrl.at(i);
            if(image.id === name)
            {
                return image.url;
            }
        }
    }

    const lookUp = articles.sort((a, b) => b.img.localeCompare(a.img)).map((post, index) => {
        if (post.favorite){
            const url = getUrlFromName(post.img);
            return <InstagramPost
                title={post.title}
                imageName={post.img}
                imageUrl={url}
                date={post.date}
                link={"/actualites/#" + index}
            />;
        } else {
            return null;
        }
    });

    return(
        <div className={'page'} id={'page_accueil'}>
            <meta
                name={"description"}
                content={"Page de présentation de l'association les Croisades d'Unnord. Organisateur de convention de " +
                    "jeux de rôles. "}
            />
            <TitleBlock title="La convention de JDR Lilloise"/>
            {presentation.map((text) =>
                <PresentationBlock
                    text={text.description}
                    img={imageUrl}
                    img_description={text.img_alt}
                />
            )}
            <TitleBlock title="Actualites"/>
            <InstagramPostRow id={"rox"}>
                {lookUp}
            </InstagramPostRow>
        </div>
    );
}

export default Accueil;