import {NavLink} from 'react-router-dom';

function NavItem(props){

    let {link, text} = props;

    return (
        <li className='nav-item'>
            <NavLink to={link} className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
                {text}
            </NavLink>
        </li>
    );
}

export default NavItem;