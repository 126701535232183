import SponsorsRow from "../Components/Sponsor/SponsorsRow";
import SponsorCard from "../Components/Sponsor/SponsorCard";
import TitleBlock from "../Components/TitleBlock/TitleBlock";
import './Views.css'
import {useEffect, useState} from "react";
import {collection, getDocs, query} from "firebase/firestore";
import {db, storage} from "../firebase";
import {getDownloadURL, listAll, ref} from "firebase/storage";

function Partenaires(){
    const [sponsors, setSponsors] = useState([]);
    //const [urlSponsorLogo, setUrlSponsorLogo] = useState([]);
    const urlSponsorLogo = [
        { url: "/assets/footer/12_singes.jpg", id: "12_singes" },
        { url: "/assets/footer/4Chemins.png", id: "4Chemins" },
        { url: "/assets/footer/iello.png", id: "iello" },
        { url: "/assets/footer/Aleades.jpg", id: "Aleades" },
        { url: "/assets/footer/Forge_ludique.jpg", id: "Forge_ludique" },
        { url: "/assets/footer/Ollapion.jpg", id: "Ollapion" },
        { url: "/assets/footer/pawns_pusher.png", id: "pawns_pusher" },
        { url: "/assets/footer/Ronhin.jpg", id: "Ronhin" },
        { url: "/assets/footer/lacatho.jpg", id: "lacatho" },
        { url: "/assets/footer/Cobayes.jpg", id: "Cobayes" },
        { url: "/assets/footer/cultura.jpg", id: "cultura" },
        { url: "/assets/footer/Junia.jpg", id: "Junia" },
        { url: "/assets/footer/junia_alumni.jpg", id: "junia_alumni" },
        { url: "/assets/footer/Philibert.jpg", id: "Philibert" },
        { url: "/assets/footer/thylGames.png", id: "thylGames" },
        { url: "/assets/footer/valty_street.png", id: "valty_street" }
    ];

    const fetchSponsors = async() => {
        const q = query(collection(db, "sponsor"));
        const querySnapshotSponsors = await getDocs(q);
        setSponsors(querySnapshotSponsors.docs.map((doc) => doc.data()));
    }
    const fetchLogos = () => {
        const postsRef = ref(storage, 'sponsor');
        listAll(postsRef).then(result => {
            // Loop over each item
            result.items.forEach((pics, index) => {
                getDownloadURL(ref(storage, pics.fullPath))
                    .then((url) => {
                        // This can be downloaded directly:
                        const xhr = new XMLHttpRequest();
                        xhr.responseType = 'blob';
                        xhr.onload = (event) => {
                            const blob = xhr.response;
                        };
                        xhr.open('GET', url);
                        xhr.send();

                        // Or inserted into an <img> element
                        const name  = url.split('?').at(0).split('%2F').at(-1).split('.').at(0);
                        const objectImage = {
                            id: name,
                            url: url
                        };
                        //setUrlSponsorLogo(objects => [...objects, objectImage]);
                    })
                    .catch((error) => {
                        // Handle any errors
                    })
            });
        })
    }

    useEffect( () => {
        //Sponsors Text
        fetchSponsors().then(() => {
            //console.log('Text request firebase');
        })

        //Sponsors images
        //fetchLogos();

        return () => {};
    }, []);

    const getUrlFromName = (name) =>
    {
        for (let i=0; i < urlSponsorLogo.length; i++) {
            const image = urlSponsorLogo.at(i);
            if(image.id === name)
            {
                return image.url;
            }
        }
    }

    return(
        <div className={'page'} id={'page_partenaires'}>
            <meta
                name={"description"}
                content={"Liste des partenaires et sponsors des croisades d'unnord."}
            />
            <TitleBlock title="Partenaires et Sponsors" id="sponsors"/>
            <SponsorsRow id='1'>
            {
                sponsors.map((sponsor) => {
                    const urlLogo = getUrlFromName(sponsor.img)
                    return(
                        <SponsorCard
                            title={sponsor.name}
                            img={sponsor.img}
                            link={sponsor.link}
                            text={sponsor.description}
                            url={urlLogo}
                        > </SponsorCard>
                    );
                })
            }
            </SponsorsRow>
        </div>
    );
}

export default Partenaires;