import PageBlock from "./PageBlock";

function PresentationBlock(props){

    let {text, img, img_description} = props;

    return(
        <PageBlock>
            <div className={'row align-center-web fixed-height-presentation'}>
                <div className={'col-lg-5 wrapper-presentationBlockImage'}>
                    <img
                        id={'affiche_presentation'}
                        className={'presentationBlockImage'}
                        src={img}
                        alt={img_description}
                    />
                </div>
                <div className={'col-lg-7'}>
                    <p className={'presentationBlockText'}>
                        {text}
                    </p>
                </div>
            </div>
        </PageBlock>
    );
}

export default PresentationBlock;