import TitleBlock from "../Components/TitleBlock/TitleBlock";
import InstagramPostRow from "../Components/InstagramPost/InstagramPostRow";
import InstagramPost from "../Components/InstagramPost/InstagramPost";
import ArticleActu from "../Components/ArticleBlock/ArticleActu";
import {collection, getDocs, orderBy} from "firebase/firestore"
import {useEffect, useState} from "react";
import {db, storage} from "../firebase";
import {getDownloadURL, listAll, ref} from "firebase/storage";

function Actualites(props) {
    //const [articles, setArticles] = useState([]);
    //const [articlesUrl, setArticlesUrl] = useState([]);
    const articles = props.articles;
    const  articlesUrl = props.articlesUrl;

    const fetchArticles = async () => {
        const querySnapshotArticles = await getDocs(collection(db, "articles"));
        //setArticles(querySnapshotArticles.docs.map((doc) => doc.data()));
    }

    const fetchImage = () => {
        const postsRef = ref(storage, "posts");
        listAll(postsRef).then(result => {
            // Loop over each item
            result.items.forEach((pics) => {
                getDownloadURL(ref(storage, pics.fullPath))
                    .then((url) => {
                        // This can be downloaded directly:
                        const xhr = new XMLHttpRequest();
                        xhr.responseType = 'blob';
                        xhr.onload = (event) => {
                            const blob = xhr.response;
                        };
                        xhr.open('GET', url);
                        xhr.send();

                        const name  = url.split('?').at(0).split('%2F').at(-1).split('.').at(0);
                        const object = {
                            url:url,
                            id:name
                        };
                        //setArticlesUrl(urls => [...urls,object]);
                        console.log(name + " : " + url);

                    })
                    .catch((error) => {
                        // Handle any errors
                    })
            });
        })
    }

    /*
    useEffect(()=>{
        //Article List
        fetchArticles().then(() => {
            console.log('Articles request Firebase');
        });

        //Articles Images
        console.log('Articles Image request Firebase');
        fetchImage();

        return () => {};
    }, []);
     */

    const getUrlFromName = (name) =>
    {
        for (let i=0; i < articlesUrl.length; i++) {
            const image = articlesUrl.at(i);
            if(image.id === name)
            {
                return image.url;
            }
        }
    }

    let child = articles.sort((a, b) => b.img.localeCompare(a.img)).map((post, index) => {
        const url = getUrlFromName(post.img);
        return <ArticleActu
            title={post.title}
            content={post.description}
            imageUrl={url}
            date={post.date}
            id={index}
        />
    });

    const lookUp = articles.sort((a, b) => b.img.localeCompare(a.img)).map((post, index) => {
        if (post.favorite){
            const url = getUrlFromName(post.img);
            return <InstagramPost
                title={post.title}
                imageName={post.img}
                imageUrl={url}
                date={post.date}
                link={"/actualites/#" + index}
            />
        } else {
            return null;
        }
    });

    return (
        <div className={'page'} id={'page_actualites'}>
            <meta
                name={"description"}
                content={"Page de présentation de l'association les croisades d'unnord. Organisateur de convention de " +
                "jeux de rôles. "}
            />
            <TitleBlock title="Actualites"/>

            <InstagramPostRow id={"rox"}>
                {lookUp}
            </InstagramPostRow>
            {child}
        </div>
    );
}

export default Actualites;