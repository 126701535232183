import FooterLinksList from "./FooterLinksList";
import FooterSocialMedia from "./FooterSocialMedia";
import FooterComment from "./FooterComment";
import FooterPartners from "./FooterPartners";
import {HashLink} from "react-router-hash-link";

function Footer(){
    return(
        <footer className={'footer-16371 bg-dark-red'}>
            <div className={'row align-center-web justify-content-center'}>
                <div className={'col-sm-12 text-center partners'}>
                    <HashLink smooth to='/partenaires/#titleBlock' >
                        <h2>Nos Partenaires</h2>
                    </HashLink>
                    <FooterPartners/>
                </div>
            </div>
            <div className={'row justify-content-center'}>
                <div id="footer_links" className={'col-md-9 text-center mt-3'}>
                    <FooterLinksList/>
                    <FooterComment text="Suivez nous sur les réseaux sociaux !"/>
                    <FooterSocialMedia/>
                </div>
            </div>
        </footer>
    );
}

export default Footer;