import { Routes, Route } from 'react-router-dom';
import Layout from "./Components/Layout/Layout";
import Accueil from "./Views/Accueil";
import Actualites from "./Views/Actualites";
import InformationsPratiques from "./Views/InformationsPratiques";
import NotFound from "./Views/NotFound";
import Partenaires from "./Views/Partenaires";
import Planning from "./Views/Planning";
import {useEffect, useState} from "react";
import {getDownloadURL, listAll, ref} from "firebase/storage";
import {db, storage} from "./firebase";
import {collection, getDocs} from "firebase/firestore";

function App() {

    const [articles, setArticles] = useState([]);
    const [articlesUrl, setArticlesUrl] = useState([]);

    const fetchArticles = async () => {
        const querySnapshotArticles = await getDocs(collection(db, "articles"));
        setArticles(querySnapshotArticles.docs.map((doc) => doc.data()));
    }

    const fetchImage = (storageName) => {
        const postsRef = ref(storage, storageName);
        listAll(postsRef).then(result => {
            // Loop over each item
            result.items.forEach((pics) => {
                getDownloadURL(ref(storage, pics.fullPath))
                    .then((url) => {
                        // This can be downloaded directly:
                        const xhr = new XMLHttpRequest();
                        xhr.responseType = 'blob';
                        xhr.onload = (event) => {
                            const blob = xhr.response;
                        };
                        xhr.open('GET', url);
                        xhr.send();

                        if(storageName === 'posts')
                        {
                            const name  = url.split('?').at(0).split('%2F').at(-1).split('.').at(0);
                            const object = {
                                url:url,
                                id:name
                            };
                            setArticlesUrl(urls => [...urls,object]);
                        }
                    })
                    .catch((error) => {
                        // Handle any errors
                    })
            });
        })
    }

    useEffect(()=>{
        //Article List
        fetchArticles().then(() => {
            //console.log('Articles request Firebase');
        });

        //Articles Images
        fetchImage('posts');

        return () => {};
    }, []);

  return (
      <Layout>
        <Routes>
            <Route exact path={'/'} element={<Accueil articles={articles} articlesUrl={articlesUrl}/>}/>
            <Route exact path={'/planning'} element={<Planning/>}/>
            <Route exact path={'/actualites'} element={<Actualites articles={articles} articlesUrl={articlesUrl}/>}/>
            <Route exact path={'/informations_pratiques'} element={<InformationsPratiques/>}/>
            <Route exact path={'/partenaires'} element={<Partenaires/>}/>
            <Route exact path={'*'} element={<NotFound/>}/>
        </Routes>
      </Layout>
  );
}

export default App;
