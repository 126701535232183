function WhereToSleepItem(props){

    let {title, img, link, content, url} = props;

    return(
        <div className={'col-md-6 overflow-hidden text-justify whereToSleepItem'}>
            <a href={link}>
                <img
                    id={img+'_presentation'}
                    className={'text-center whereToSleepImage'}
                    src={url}
                    alt={title}
                />
            </a>
            <a href={link}>
                <h3>{title}</h3>
            </a>
            <p>{content}</p>
        </div>
    );
}

export default WhereToSleepItem;