import {ExternalLink} from "react-external-link";


function FooterSocialMediaItem(props){

    let {link, path, classe, color} = props;

    return(
        <li className='col'>
            <ExternalLink href={link}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill={color}
                    className={classe+' scaleTransitionImage'}
                    viewBox="0 0 16 16">
                    <path d={path}/>
                </svg>
            </ExternalLink>
        </li>
    );
}

export default FooterSocialMediaItem;