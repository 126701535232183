import './InstagramPost.css'

function InstagramPostRow(props){
    let {id,children} = props;

    return(
        <div id={'Instagram_posts_row_'+id} className={'row align-center-web instagramPostRow'}>
            {children}
        </div>
    );
}

export default InstagramPostRow;