import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBXhWFHLh9sFdc1-KovIWca1fcWFJSrnBA",
    authDomain: "croisades-d-unnord.firebaseapp.com",
    projectId: "croisades-d-unnord",
    storageBucket: "croisades-d-unnord.appspot.com",
    messagingSenderId: "443888137731",
    appId: "1:443888137731:web:7cf18eb0604abe31a8c036"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app)
const storage = getStorage(app);


export {db}
export {storage}