import './Footer.css';
import {HashLink} from "react-router-hash-link";

function FooterLinkItem(props){

    let {link, text} = props;

    return(
        <li className='col'>
            <HashLink smooth className={'linkItem'} to={link}>
                {text}
            </HashLink>
        </li>
    );
}

export default FooterLinkItem;