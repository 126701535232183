import NavItem from "./NavItem";

function NavLinksList(){
    return(
        <ul className='navbar-nav' >
            <NavItem link='/' text='Accueil' />
            <NavItem link='/planning' text='Planning' />
            {/*<li className="nav-item"><a className="inactive" href="https://www.helloasso.com/associations/les-croisades-d-unnord/evenements/croisades-d-unnord-2024" aria-current="page">Billetterie</a></li>*/}
            <NavItem link='/actualites' text='Actualités'/>
            <NavItem link='/informations_pratiques' text='Informations pratiques'/>
        </ul>
    );
}

export default NavLinksList;