import FooterIcon from "./FooterIcon";
import {storage} from "../../../../firebase";
import {getDownloadURL, listAll, ref} from "firebase/storage";
import {useEffect, useState} from "react";

function FooterPartners(){
    const urlImages = ["4Chemins.png",
        "12_singes.jpg",
        "Aleades.jpg",
        "Cobayes.jpg",
        "cultura.jpg",
        "Forge_ludique.jpg",
        "iello.png",
        "Junia.jpg",
        "junia_alumni.jpg",
        "Lutetia.jpg",
        "Ollapion.jpg",
        "pawns_pusher.png",
        "Philibert.jpg",
        "Ronhin.jpg",
        "thylGames.png",
        "lacatho.jpg",
        "valty_street.png"]

    return(
        <div className='mb-4'>
            <ul className='list-unstyled text-center row'>
                {
                    urlImages.map((url) => {
                        return (
                            <FooterIcon
                                key={url.split(".")[0]}
                                path={"/assets/footer/" + url}
                            />
                        );
                    })
                }
            </ul>
        </div>
    );
}

export default FooterPartners;