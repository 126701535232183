function ArticleActu(props){

    let {title, content, imageUrl, date, id} = props;

    return(
        <div id={id} className={'align-center-web rounded-card-white'}>
            <div className={'row'}>
                <div className={'col-lg-12'}>
                    <h2>{title}</h2>
                    <p><small>{date}</small></p>
                </div>
            </div>
            <div className={"row"}>
                <div className={'col-md-4 overflow-hidden'}>
                    <img
                        className="bd-placeholder-img"
                        role="img"
                        aria-label={title}
                        focusable="false"
                        src={imageUrl}
                        alt={title}
                        width='100%'
                    />
                </div>
                <div className={'col-md-8'}>
                    <p className={'text-justify'}>{content}</p>
                </div>
            </div>
        </div>
    );

}

export default ArticleActu;