import {HashLink} from "react-router-hash-link";


function FooterIcon(props){

    let {path, text} = props;

    return(
        <li className='col'>
            <HashLink smooth to='/partenaires/#titleBlock' >
                <img src={path} height="80em" width="80em" style={{objectFit: 'cover'}} className="footerIcon scaleTransitionImage" alt={text} />
            </HashLink>
        </li>
    );
}

export default FooterIcon;